<template>
    <div class="col-12 col-lg-4" style="margin-bottom: 30px">
        <div class="blog-block blog-item-cursor">
          <content-loader width="600" height="500" preserveAspectRatio="none"></content-loader>
        </div>
    </div>
</template>

<script>
    import {ContentLoader} from 'vue-content-loader'

    export default {
        components: {
            ContentLoader
        },
    }
</script>
