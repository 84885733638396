<template>
  <div>
    <div class="blog-page">
      <div class="blog-page-sec">
        <div class="container">
          <div class="row">

            <ContentLoader v-for="index in loaderCount" :key="index"/>

            <Item :account-id="accountId" :base-url="baseUrl" :slug="slug" :data="item"
                  v-for="item in items"
                  :key="item.id" :seo="seo"/>

          </div>
          <div v-if="pageCount > 1" class="page-navigation paginate-css">
            <paginate
                :value="this.pageNumber"
                :page-count="pageCount"
                :page-range="1"
                :margin-pages="2"
                :click-handler="pageChange"
                prev-text='<i class="fas fa-chevron-left"></i>'
                next-text='<i class="fas fa-chevron-right"></i>'
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'page-link'">
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
body {

}

.paginate-css {

}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3253dc !important;
  border-color: #3253dc !important;
}

</style>

<script>

import moment from 'moment'
import axios from 'axios';
import Item from './Item'
import ContentLoader from "../../content-loader/ContentLoader";

export default {
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    baseUrl: {
      type: String
    },
    slug: {
      type: Boolean
    },
    seo: {
      type: Boolean
    },
  },
  data() {
    return {
      items: [],
      finalPageSize: 10,
      pageNumber: 1,
      pageCount: 0,
      loaderCount: 6
    };
  },
  components: {
    Item,
    ContentLoader
  },
  methods: {
    pageChange(next) {
      this.items = [];
      this.pageNumber = next;
      this.getData();
    },
    async getData() {
      this.loaderCount = 6;
      console.log(`blog/widget/list?accountId=${this.accountId}&pageSize=${decodeURIComponent(this.finalPageSize)}&pageNumber=${this.pageNumber - 1}`);
      let response = await axios.get(`blog/widget/list?accountId=${this.accountId}&pageSize=${decodeURIComponent(this.finalPageSize)}&pageNumber=${this.pageNumber - 1}`);
      const res = response.data;
      if (res && res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i];
          this.items.push({
            id: item.id,
            title: item.title,
            date: moment(item.publishedAt ? item.publishedAt: item.createdAt, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD, YYYY hh:mma'),
            categories: this.getCategoriesString(item.categories),
            featuredImageUrl: item.featuredImageUrl,
            slug: item.slug[item.slug.length - 1]
          });
        }
        const pagination = res.pagination;
        const totalRecords = pagination.total;
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize);
      }
      this.loaderCount = 0;
    },
    getCategoriesString(categories) {
      let categoryDisplay = [];
      for (let i = 0; i < categories.length; i++) {
        categoryDisplay.push({
          text: categories[i].name,
          color: categories[i].color
        });
      }
      if (categoryDisplay.length === 0) {
        categoryDisplay.push({
          text: ''
        });
      }
      return categoryDisplay;
    }
  },
  mounted() {
    console.log('Calling api');
    this.getData();
    const self = this;
    setTimeout(function () {
      self.$matomo.setCustomDimension(1, self.accountId);
      self.$matomo.setCustomVariable(1, 'accountId', self.accountId, 'page');
      self.$matomo.trackPageView('Blog List Page');
    }, 2000);
  },
  beforeMount() {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize;
    }
    console.log('List page');
  }
}

</script>
