<template>
  <div id="app">
    <Navigation :account-id="accountId" :page-size="pageSize" :blog-details="blogDetails" :base-url="baseUrl"
                :slug="slug" :template-id="templateId" :details-template-id="detailsTemplateId" :seo="seo"/>
  </div>
</template>

<style lang="scss">
@import "assets/css/style.css";
@import "assets/css/slick.css";
@import "assets/css/animate.min.css";
@import "assets/css/font-awesome.min.css";
@import "assets/css/responsive.css";
</style>


<script>

import Navigation from './components/navigation/Navigation'

export default {
  props: {
    prop1: {},
    prop2: {},
    prop3: {},
    accountId: {
      type: String
    },
    templateId: {
      type: Number
    },
    detailsTemplateId: {
      type: Number
    },
    pageSize: {
      type: Number
    },
    blogDetails: {
      type: Boolean
    },
    baseUrl: {
      type: String
    },
    slug: {
      type: Boolean
    },
    seo: {
      type: Boolean
    },
  },
  components: {
    Navigation
  }
}

</script>
