<template>
  <div>
    <SVGI/>
    <div class="blog-details-page" v-if="title">
      <div class="blog-page-sec">
        <div>
          <div class="row">
            <div class="col-12">
              <div class="blog-details" style="background: rgb(19, 91, 166); padding: 90px 76px 60px;">
                <div style="max-width: 980px;margin: auto;">
                  <b-row>
                    <b-col cols="4">
                      <div class="blog-image blog-header-image-details blog-header-image-poscast"
                           v-bind:style="{'background-image':`url(${featuredImageUrl})`}">
                      </div>
                    </b-col>
                    <b-col cols="8">
                      <h4 class="heading">{{ title }}</h4>
                    </b-col>
                    <b-col cols="12" v-if="mediaUrl">
                      <div id="audiowrap">
                        <div id="audio0">
                          <vue-plyr>
                            <audio controls crossorigin playsinline>
                              <source :src="mediaUrl" type="audio/mp3"/>
                            </audio>
                          </vue-plyr>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="container">
                <div class="blog-content blog-content-div">
                  <div class="blog-details-cat">
                    <Tag :data="item" v-for="item in categories" :key="item.text"/>
                    {{ this.date }}
                  </div>
                  <p class="blog-text" v-html="text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

body {
  background: #ffffff !important;
}

.blog-header-image-poscast {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.heading {
  margin-top: 75px;
  color: rgb(255, 255, 255);
  font-size: 52px;
  line-height: 1.3;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  max-height: 202.8px;
}

.blog-text {
  color: rgb(0, 0, 0);
  font-size: 20px;
  line-height: 1.6;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  word-break: break-word;
}

.blog-content-div {
  max-width: 820px;
  margin: auto
}

audio {
  display: none;
}

.plyr__controls {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
  padding: 20px 20px 20px 13px !important;
  width: 100% !important;
}

</style>

<script>

import moment from 'moment'
import axios from 'axios';
import Tag from '../Tag'
import SVGI from '../../svg'

export default {
  props: {
    accountId: {
      type: String
    },
    slug: {
      type: Boolean
    },
  },
  data() {
    return {
      title: '',
      text: '',
      date: '',
      categories: [],
      featuredImageUrl: '',
      finalBlogId: '',
      finalSlugId: '',
      seoPageTitle: '',
      seoMetaDescription: '',
      mediaUrl: ''
    }
  },
  components: {
    Tag,
    SVGI
  },
  methods: {
    async getData() {
      let response = await axios.get(`blog/widget/details?accountId=${this.accountId}&blogId=${this.finalBlogId}&slug=${this.finalSlugId}`);
      const res = response.data;
      if (res && res.status) {
        const item = res.value;
        this.title = item.title;
        this.text = item.data;
        this.date = moment(item.publishedAt ? item.publishedAt : item.createdAt, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD, YYYY hh:mma');
        this.categories = this.getCategoriesString(item.categories);
        this.featuredImageUrl = item.featuredImageUrl;
        this.seoPageTitle = item.seoPageTitle;
        this.seoMetaDescription = item.seoMetaDescription;
        this.mediaUrl = item.audioUrl
      }
    },
    getCategoriesString(categories) {
      let categoryDisplay = [];
      for (let i = 0; i < categories.length; i++) {
        categoryDisplay.push({
          text: categories[i].name,
          color: categories[i].color
        });
      }
      if (categoryDisplay.length === 0) {
        categoryDisplay.push({text: '', isEmpty: true});
      }
      return categoryDisplay;
    },
    loadPlayer() {


    },
  },
  mounted() {
    this.getData();
    const self = this;
    setTimeout(function () {
      self.$matomo.setCustomDimension(1, self.accountId);
      self.$matomo.setCustomVariable(1, 'accountId', self.accountId, 'page');
      self.$matomo.trackPageView('Blog Details Page');
    }, 2000);
  },
  beforeMount() {
    if (this.slug) {
      this.finalSlugId = this.$route.params.slugId;
    } else {
      this.finalBlogId = this.$route.query.blogId;
    }
  }
}
</script>
