<template>
  <div class="col-12 col-lg-4" style="margin-bottom: 30px" @click="openDetails(data.id, data.slug)">
    <div class="blog-block blog-item-cursor">
      <div class="blog-image blog-header-image"
           v-bind:style="{'background-image':`url(${data.featuredImageUrl})`}"></div>
      <!--<div class="blog-image"><img height="250px" width="100%" :src="data.featuredImageUrl" alt=""/></div>-->
      <div class="blog-content">
        <div class="tag-wrapper">
          <Tag :data="item" v-for="item in data.categories" :key="item.text"/>
        </div>
        <h5 class="header">{{ data.title }}</h5>
        <div class="blog-by">{{ data.date }}</div>
      </div>
    </div>
  </div>
</template>

<style>

.tag-wrapper {
  line-height: 1.5em;
}

.header {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.blog-item-cursor {
  cursor: pointer;
}

.blog-header-image {
  width: 100%;
  height: 225px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

</style>

<script>

import Tag from '../Tag'

export default {
  props: {
    data: Object,
    accountId: String,
    baseUrl: String,
    slug: {
      type: Boolean
    },
    seo: {
      type: Boolean
    },
  },
  data() {
    return {
      extraWindow: null
    }
  },
  components: {
    Tag
  },
  methods: {
    openDetails(id, slu) {
      if (this.slug) {
        let newUrl = this.baseUrl;
        if (this.baseUrl.endsWith('/')) {
          newUrl = `${this.baseUrl}${slu}`
        } else {
          newUrl = `${this.baseUrl}/${slu}`
        }
        if (this.seo) {
          newUrl += `#/${slu}`
        }
        this.extraWindow = window.open(newUrl, '_self');
      } else {
        let url = `${this.baseUrl}?blogId=${id}`
        if (this.seo) {
          url = `${this.baseUrl}/${slu}?blogId=${id}`
        }
        this.extraWindow = window.open(url, '_self');
      }
      //location.reload();
    },
    reloadWindow() {
      if (this.extraWindow) {
        this.extraWindow.location.reload();
      }
    }
  },
  mounted() {
    window.addEventListener("hashchange", this.reloadWindow, false);
  }
}

</script>
