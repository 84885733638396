<template>
    <a href="" class="tag tag-color"
       :style="dynamicStyle"
       style="margin-right: 5px; margin-top: 5px;">{{data.text}}</a>
</template>

<script>
    export default {
        props: {
            data: Object
        },
        computed: {
            dynamicStyle() {
                return {
                    color: `${this.data.color}`,
                    border: `1px solid ${this.data.color} !important`,
                };
            },
        },
    }
</script>
