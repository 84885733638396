<template>
  <div>
    <PodCastDetails v-if="blogDetails && detailsTemplateId===2" :account-id="accountId" :slug="slug"/>
    <BlogDetails
        v-if="blogDetails && (detailsTemplateId===null || detailsTemplateId === undefined || detailsTemplateId===1)"
        :account-id="accountId"
        :slug="slug"/>
    <BlogList v-if="!blogDetails && (!templateId || templateId===1)" :account-id="accountId" :page-size="pageSize"
              :base-url="baseUrl" :slug="slug" :seo="seo"/>
    <BlogListTemplate2 v-if="!blogDetails && (templateId===2)" :account-id="accountId" :page-size="pageSize"
                       :base-url="baseUrl" :slug="slug" :seo="seo"/>
  </div>
</template>

<script>

import BlogDetails from "../components/details/BlogDetails";
import PodCastDetails from "../components/details/podcast/Details";
import BlogList from "../components/list/template1/BlogList";
import BlogListTemplate2 from "../components/list/template2/BlogListTemplate2";

export default {
  props: {
    accountId: {
      type: String
    },
    templateId: {
      type: Number
    },
    detailsTemplateId: {
      type: Number
    },
    pageSize: {
      type: Number
    },
    blogDetails: {
      type: Boolean
    },
    baseUrl: {
      type: String
    },
    slug: {
      type: Boolean
    },
    seo: {
      type: Boolean
    },
  },
  components: {
    BlogList,
    BlogDetails,
    BlogListTemplate2,
    PodCastDetails
  },
  mounted() {

  }
}
</script>
