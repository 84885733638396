<template>
  <div>
    <!--<vue-headful
            :title="seoPageTitle"
            :description="seoMetaDescription"
    />-->
    <div class="blog-details-page" v-if="title">
      <div class="blog-page-sec">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="blog-details">
                <div :class="{'show-image-from-top': showImageFromTop, 'blog-image': !showImageFromTop}" v-if="!hideFeatureImage" class="blog-header-image-details"
                     v-bind:style="{'background-image':`url(${featuredImageUrl})`}">
                  <!--<img :src="featuredImageUrl"
                       style="width: 100%; height: 400px; object-fit: cover;"
                       alt=""/>-->
                </div>
                <div class="blog-content">
                  <div class="blog-details-cat">
                    <Tag :data="item" v-for="item in categories" :key="item.text"/>
                    {{ this.date }}
                  </div>
                  <h4>{{ title }}</h4>
                  <p v-html="text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scrollup"><i class="fas fa-chevron-up"></i></div>
  </div>
</template>

<style>

body {
  background: #ffffff !important;
}

.blog-header-image-details {
  width: 100%;
  height: 325px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.show-image-from-top {
  background-position: top !important;
}

</style>

<script>

import moment from 'moment'
import axios from 'axios';
import Tag from './Tag'

export default {
  props: {
    accountId: {
      type: String
    },
    slug: {
      type: Boolean
    },
  },
  data() {
    return {
      title: '',
      text: '',
      date: '',
      categories: [],
      featuredImageUrl: '',
      finalBlogId: '',
      finalSlugId: '',
      hideFeatureImage: false,
      showImageFromTop: false,
      seoPageTitle: '',
      seoMetaDescription: ''
    }
  },
  components: {
    Tag
  },
  methods: {
    async getData() {
      let response = await axios.get(`blog/widget/details?accountId=${this.accountId}&blogId=${this.finalBlogId}&slug=${this.finalSlugId}`);
      const res = response.data;
      if (res && res.status) {
        const item = res.value;
        this.title = item.title;
        this.text = item.data;
        this.date = moment(item.publishedAt ? item.publishedAt : item.createdAt, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD, YYYY hh:mma');
        this.categories = this.getCategoriesString(item.categories);
        this.featuredImageUrl = item.featuredImageUrl;
        this.seoPageTitle = item.seoPageTitle;
        this.seoMetaDescription = item.seoMetaDescription;
      }
    },
    getCategoriesString(categories) {
      let categoryDisplay = [];
      for (let i = 0; i < categories.length; i++) {
        categoryDisplay.push({
          text: categories[i].name,
          color: categories[i].color
        });
      }
      if (categoryDisplay.length === 0) {
        categoryDisplay.push({text: '', isEmpty: true});
      }
      return categoryDisplay;
    }
  },
  mounted() {
    this.getData();
    const self = this;
    setTimeout(function () {
      self.$matomo.setCustomDimension(1, self.accountId);
      self.$matomo.setCustomVariable(1, 'accountId', self.accountId, 'page');
      self.$matomo.trackPageView('Blog Details Page');
    }, 2000);
  },
  beforeMount() {
    if (this.slug) {
      this.finalSlugId = this.$route.params.slugId;
    } else {
      this.finalBlogId = this.$route.query.blogId;
    }
    this.hideFeatureImage = this.$route.query.hideFeatureImage === 'true';
    this.showImageFromTop = this.$route.query.showImageFromTop === 'true';
    console.log('showImageFromTop',this.showImageFromTop)
  }
}
</script>
