<template>
  <div id="app">
    <Header/>
    <router-view :account-id="accountId" :page-size="pageSize" :blog-details="blogDetails" :base-url="baseUrl"
                 :slug="slug" :template-id="templateId" :details-template-id="detailsTemplateId" :seo="seo"/>
    <Footer/>
  </div>
</template>


<script>

import Header from './Header'
import Footer from './Footer'

export default {
  props: {
    accountId: {
      type: String
    },
    templateId: {
      type: Number
    },
    detailsTemplateId: {
      type: Number
    },
    pageSize: {
      type: Number
    },
    blogDetails: {
      type: Boolean
    },
    baseUrl: {
      type: String
    },
    slug: {
      type: Boolean
    },
    seo: {
      type: Boolean
    },
  },
  components: {
    Header,
    Footer
  }
}

</script>
