import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import axios from 'axios'
import Paginate from 'vuejs-paginate'
import Pagination from 'vue-pagination-2';
import router from './router'
import VueMeta from 'vue-meta'
import vueHeadful from 'vue-headful';
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.component('paginate', Paginate);
Vue.component('pagination', Pagination);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(vueCustomElement);
Vue.use(VueMeta);
Vue.use(VuePlyr, {plyr: {}})
Vue.component('vue-headful', vueHeadful);

/*******Matomo Setup*******/
import VueMatomo from 'vue-matomo'

Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_HOST,
    siteId: process.env.VUE_APP_MATOMO_SITE_ID,
    trackerFileName: 'matomo',
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
});
/********************************/

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

App.router = router;

const accountId = document.getElementsByTagName('blog-widget')[0].getAttribute("account-id");
const cssId = document.getElementsByTagName('blog-widget')[0].getAttribute("css-id");

axios.get(`/account/${accountId}/css/${cssId}`).then(data => {

    let css = '';
    if (data && data.data && data.data.status) {
        css = data.data.value.css;
    }

    axios.get(`${process.env.VUE_APP_SELF_HOST}/css/app.css`).then(data => {
        Vue.customElement('blog-widget', App, {
            shadow: true,
            shadowCss: `${data.data} ${css}`,
            props: ['prop1',
                'prop2',
                'prop3']
        });
    });
});

/*Vue.customElement('blog-widget', App, {
    props: ['prop1',
        'prop2',
        'prop3']
});*/
