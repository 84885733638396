<template>
  <section class="sec6-area">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="assembled-item-top d-flex">
            <div>
              <h5>The NeuroOne <span>blog Resources</span></h5>
            </div>
            <div class="ml-auto">
              <span class="left-arrow" @click="showPrev">
                <img src="@/assets/images/left-arrow-long.png" alt="">
              </span>
              <span style="margin-left: 5px; margin-right: 5px"></span>
              <span class="right-arrow" @click="showNext">
                <img src="@/assets/images/right-arrow-long.png" alt="">
              </span>
            </div>
          </div>

          <VueSlickCarousel v-bind="settings" v-if='data.length' @init="progress"
                            @lazyLoad="progress" @afterChange="afterChange" ref="carousel">
            <div v-for="mainItem in data" :key="'key'+new Date().getTime()+mainItem.id">
              <Item :account-id="accountId" :base-url="baseUrl" :slug="slug" :item="item" v-for="item in mainItem"
                    :key="item.id" :seo="seo"/>
            </div>

            <template #prevArrow>

            </template>

            <template #nextArrow>

            </template>

          </VueSlickCarousel>

        </div>
      </div>
    </div>
  </section>
</template>

<style>
@import "../../../assets/css/neuroone.css";

.slick-slide {
  padding-right: 10px;
}

.title-class {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
}

.title-body {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import Item from './Item'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import axios from "axios";
import moment from "moment";

export default {
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    baseUrl: {
      type: String
    },
    slug: {
      type: Boolean
    },
    seo: {
      type: Boolean
    },
  },
  components: {
    VueSlickCarousel,
    Item
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        centerMode: false,
        infinite: false,
        slidesToShow: 3,
        rows: 2,
        slidesPerRow: 1,
        accessibility: false,
        lazyLoad: "ondemand",
        adaptiveHeight: true,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      },
      data: [],
      finalPageSize: 10,
      pageNumber: 1,
      pageCount: 0,
      currentSlide: 0
    }
  },
  watch: {
    currentSlide: function (val) {
      const totalVisible = (val * this.settings.rows + this.settings.slidesToShow * this.settings.rows)
      if (totalVisible === this.data.length) {
        this.pageNumber = this.pageNumber + 1;
        console.log(this.pageNumber)
        this.getData();
      }
    }
  },
  methods: {
    progress() {
    },
    async getData() {
      let response = await axios.get(`blog/widget/list?accountId=${this.accountId}&pageSize=${decodeURIComponent(this.finalPageSize)}&pageNumber=${this.pageNumber - 1}`);
      const res = response.data;
      if (res && res.status) {
        let arr = [];
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i];
          arr.push({
            id: item.id,
            title: item.title,
            date: moment(item.publishedAt ? item.publishedAt: item.createdAt, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD, YYYY hh:mma'),
            categories: this.getCategoriesString(item.categories),
            featuredImageUrl: item.featuredImageUrl,
            slug: item.slug[item.slug.length - 1]
          });
          if ((i + 1) % 1 === 0) {
            this.data.push(arr);
            arr = []
          }
        }
        if (arr.length) {
          this.data.push(arr);
        }
        const pagination = res.pagination;
        const totalRecords = pagination.total;
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize);
      }
    },
    afterChange(slideIndex) {
      this.currentSlide = slideIndex;
    },
    showPrev() {
      if (this.currentSlide > 0)
        this.$refs.carousel.prev()
    },
    showNext() {
      this.$refs.carousel.next()
    },
    getCategoriesString(categories) {
      let categoryDisplay = [];
      for (let i = 0; i < categories.length; i++) {
        categoryDisplay.push({
          text: categories[i].name,
          color: categories[i].color
        });
      }
      if (categoryDisplay.length === 0) {
        categoryDisplay.push({
          text: ''
        });
      }
      return categoryDisplay;
    }
  },
  mounted() {
    this.getData();
  },
  beforeMount() {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize;
    }
  }
}

</script>
